import { IDossierDto } from "../models/DTOs";
import { IDossier, ILeistungsposition } from "./../models/Types";
import {
  Dossierdata,
  IMetadata,
  IMetadataItem,
  PositionData,
} from "./Metadata";

const getParsedValue = (value: string | number) => (
  metadata: IMetadataItem
) => {
  switch (metadata.type) {
    case "string":
      return value;
    case "Date":
      return value ? new Date(value) : new Date(1900, 0, 1); //Nulldate if no value is given
    case "number":
      return Number(value);
    case "boolean":
      return !!value;
  }
};

const mapObj = <T, TD extends Object>(obj: T) => (dto: TD) => (
  data: IMetadata<T>
) => {
  for (let key in data) {
    if (data[key].mappermethod) {
      data[key].mappermethod!(obj);
    }
    if (dto.hasOwnProperty(key as string)) {
      const value = dto[key as any] as string | number;
      obj[key] = getParsedValue(value)(data[key]) as any;
    }
  }
  return obj;
};

export const mapDossierDto = (dto: IDossierDto): IDossier => {
  Dossierdata.Leistungspositionen.mappermethod = mapPosition(dto);
  return mapObj({} as IDossier)(dto)(Dossierdata);
};

const mapPosition = (dto: IDossierDto) => (dos: IDossier) => {
  try {
    if (!dto.LeistungspositionenXML) {
      dos.Leistungspositionen = [];
      return;
    }
    const posJson: any[] = JSON.parse(dto.LeistungspositionenXML);
    if (posJson && posJson.length > 0) {
      dos.Leistungspositionen = posJson.map((item) =>
        mapObj({} as ILeistungsposition)(item)(PositionData)
      );
    } else {
      dos.Leistungspositionen = [];
    }
  } catch {
    dos.Leistungspositionen = [];
  }
};
