import {
  Button,
  IconButton,
  Popover,
  Theme,
  Typography,
} from "@material-ui/core";
import FilterListIcon from "@material-ui/icons/FilterList";
import { createStyles, makeStyles } from "@material-ui/styles";
import React, { useCallback } from "react";
import { useRessource } from "../Context/RessourceContext";
import { IMetadata } from "../Service/Metadata";
import { ITableDefinition } from "./DossierTable";
import Filtertable, { IFilterFunction } from "./Filtertable";
import { IKeyValue } from "./Select";

interface IProps<T> {
  title: string;
  table: ITableDefinition<T>;
  filter: IFilterFunction<T>[];
  applyFilter: (func: IFilterFunction<T>[]) => void;
  clearFilter: () => void;
  className?: string;
  metadata: IMetadata<T>;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    filter: { marginTop: 15, marginBottom: 15 },
    popper: {
      marginLeft: 20,
    },
    popperPaper: {
      padding: 15,
    },
    actions: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
    },
  })
);

const TableFilter = <T extends unknown>(props: IProps<T>) => {
  const classes = useStyles({});
  const [getLabel] = useRessource();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const handleClick = (ev: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(ev.currentTarget);
  };
  const [filter, setFilter] = React.useState(props.filter);

  const getColumnsToFilter = useCallback(
    <T extends unknown>(def: ITableDefinition<T>): IKeyValue[] => {
      return def.columns
        .filter((col) => props.metadata[col.key as string]?.type !== "array")
        .map((col) => {
          return {
            key: col.key,
            value: getLabel(
              col.label ? col.label : `${def.entityname}.${col.key}`
            ),
          } as IKeyValue;
        });
    },
    [getLabel, props.metadata]
  );

  const updateFilter = (index: number) => (func: IFilterFunction<T>) => {
    const listCopy = [...filter];
    listCopy.splice(index, 1, func);
    setFilter(listCopy);
  };

  const onRemoveFilter = (index: number) => {
    const listCopy = [...filter];
    listCopy.splice(index, 1);
    setFilter(listCopy);
  };

  const addFilter = (func: IFilterFunction<T>) => {
    setFilter([...filter, func]);
  };

  return (
    <>
      <IconButton color="primary" onClick={handleClick}>
        <FilterListIcon />
      </IconButton>
      <Popover
        className={classes.popper}
        PaperProps={{ className: classes.popperPaper }}
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Typography variant="subtitle1">{props.title}</Typography>
        <Filtertable<T>
          metadata={props.metadata}
          className={classes.filter}
          columns={getColumnsToFilter(props.table)}
          filter={filter}
          addFilter={addFilter}
          removeFilter={onRemoveFilter}
          updateFilter={updateFilter}
        />
        <div className={classes.actions}>
          <Button
            onClick={() => {
              props.clearFilter();
              setFilter([]);
              setAnchorEl(null);
            }}
          >
            {getLabel("ExtranetTable.FilterRemove")}
          </Button>
          <Button
            onClick={() => {
              props.applyFilter(filter);
              setAnchorEl(null);
            }}
            color="primary"
          >
            {getLabel("ExtranetTable.FilterApply")}
          </Button>
        </div>
      </Popover>
    </>
  );
};

export default TableFilter;
