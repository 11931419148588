import { IDossier } from "../models/Types";
import { ILeistungsposition } from "./../models/Types";

type MapperMethod<T> = (item: T) => void;

type FormatFunction = (value: number) => string;

export type IMetadataItem<T = any, TU = any> = {
  type: "string" | "boolean" | "number" | "Date" | "array";
  optional?: boolean;
  arrayType?: IMetadata<TU>;
  mappermethod?: MapperMethod<T>;
  format?: FormatFunction;
};

export type IMetadata<T> = {
  [key in keyof T]: IMetadataItem<T>;
};

const formatTwoDecimal = (value: number) => {
  return value.toFixed(2);
};

const defaultMetadata: IMetadataItem = { type: "string", optional: false };

export const PositionData: IMetadata<ILeistungsposition> = {
  LeistungspositionsNr: defaultMetadata,
  AnfrageNr: defaultMetadata,
  AnteilKunde: { type: "number" },
  AnteilSSS: { type: "number" },
  Ausgleich: { type: "number" },
  Behandlungbis: { type: "Date" },
  Behandlungvon: { type: "Date" },
  Behandlungsart: defaultMetadata,
  BehandlungsartManuellGesetzt: defaultMetadata,
  Bemerkung: defaultMetadata,
  DokumentNr: defaultMetadata,
  Forderung: { type: "number", format: formatTwoDecimal },
  ImportNr: defaultMetadata,
  KVG: { type: "number", format: formatTwoDecimal },
  KantonstriageNr: defaultMetadata,
  Leistungsbezeichnung: defaultMetadata,
  Leistungsdetail: defaultMetadata,
  Leistungserbringer: defaultMetadata,
  Leistungspositionstyp: defaultMetadata,
  Nettoleistung: { type: "number", format: formatTwoDecimal },
  Quote: { type: "number" },
  RechnungsNr: defaultMetadata,
  Rechnungsbetrag: { type: "number", format: formatTwoDecimal },
  Rechnungsdatum: defaultMetadata,
  RegressNr: defaultMetadata,
  Selbstbehalt: { type: "number", format: formatTwoDecimal },
  SpitalNr: defaultMetadata,
  Uebertrag: { type: "number", format: formatTwoDecimal },
  VVG: { type: "number", format: formatTwoDecimal },
  nichtversichert: { type: "number", format: formatTwoDecimal }
};

export const Dossierdata: IMetadata<IDossier> = {
  Ereignisdatum: { type: "Date" },
  Abschlussdatum: { type: "Date" },
  Agentur: defaultMetadata,
  AgenturNr: defaultMetadata,
  DossierErledigungsgrund: defaultMetadata,
  DossierErledigungsgrundD: defaultMetadata,
  DossierErledigungsgrundF: defaultMetadata,
  DossierErledigungsgrundI: defaultMetadata,
  DossierNr: defaultMetadata,
  Dossierbearbeiter: defaultMetadata,
  DossierbearbeiterMaNr: defaultMetadata,
  Erledigt: { type: "boolean" },
  Fakturiert: { type: "number", format: formatTwoDecimal },
  Haftungsgrund: defaultMetadata,
  HauptKundenNr: defaultMetadata,
  KundenNr: defaultMetadata,
  LetzteRechnungstellung: { type: "Date" },
  LetzterZahlungsEingang: { type: "Date" },
  Versicherter: defaultMetadata,
  VersicherterNr: defaultMetadata,
  Zahlungseingang: { type: "number", format: formatTwoDecimal },
  LeistungspositionenXml: { type: "string" },
  Leistungspositionen: { type: "array", arrayType: PositionData }
};
