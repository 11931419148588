import { useState } from "react";

type FormState<T> = [
  T,
  React.Dispatch<React.SetStateAction<T>>,
  (key: keyof T) => (value: string | number | boolean) => void
];

const useFormState = <T>(initialValue: T): FormState<T> => {
  const [state, setState] = useState(initialValue);

  const handleChange = (key: keyof T) => (value: string | number | boolean) => {
    setState({ ...state, [key]: value });
  };
  return [state, setState, handleChange];
};

export default useFormState;
