import React from "react";
import { makeStyles, createStyles } from "@material-ui/styles";
import {
  Theme,
  Dialog,
  DialogTitle,
  DialogContent,
  CircularProgress,
  DialogActions,
  Button
} from "@material-ui/core";
import { useRessource } from "../Context/RessourceContext";

interface IProps {
  title: string;
  onAbort?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    progress: { display: "inline-block", margin: "0 auto" },
    root: {
      display: "flex"
    }
  })
);

const LoadingDialog = (props: IProps) => {
  const classes = useStyles({});
  const [getLabel] = useRessource();
  return (
    <Dialog open={true}>
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent className={classes.root}>
        <CircularProgress className={classes.progress} color="primary" />
      </DialogContent>
      {props.onAbort && (
        <DialogActions>
          <Button onClick={props.onAbort} color="primary">
            {getLabel("ExtranetGeneral.Abort")}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default LoadingDialog;
