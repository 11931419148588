import React, { useCallback } from "react";
import { useSnackbar } from "notistack";
import { Typography, createStyles, makeStyles, Theme } from "@material-ui/core";
import { IFetchError } from "../models/Types";
import { useRessource } from "../Context/RessourceContext";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    error: {
      whiteSpace: "pre-wrap",
      wordWrap: "normal",
      maxWidth: 250
    }
  })
);

const useErrorToast = () => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [getLabel, , isInDictionary] = useRessource();

  const showError = useCallback(
    (err: IFetchError | string) => {
      console.error(
        typeof err === "string" ? err : `${err.Code}\n${err.Message}`
      );
      enqueueSnackbar(
        <Typography variant="body2" className={classes.error}>
          {typeof err === "string"
            ? err
            : isInDictionary(`ExtranetMessage.${err.Code}`)
            ? getLabel(`ExtranetMessage.${err.Code}`)
            : `${err.Code} \n${err.Message}`}
        </Typography>,
        { variant: "error" }
      );
    },
    [classes.error, enqueueSnackbar, getLabel, isInDictionary]
  );
  return { showError };
};

export default useErrorToast;
