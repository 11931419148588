import { OutlinedInputProps } from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import React from "react";
interface IProps {
  onChange: (val: string | Date) => void;
  value: string | Date;
  inputProps?: Partial<OutlinedInputProps>;
  onOk?: () => void;
}

const DatePicker = (props: IProps) => {
  return (
    <KeyboardDatePicker
      autoOk
      onAccept={props.onOk}
      variant="inline"
      inputVariant="outlined"
      format="dd.MM.yyyy"
      value={props.value}
      size="small"
      invalidDateMessage=""
      InputProps={props.inputProps}
      onChange={(
        date: MaterialUiPickersDate,
        value: string | null | undefined
      ) => props.onChange(date ? (date as Date) : (value as string))}
    />
  );
};

export default DatePicker;
