import { IUser } from "../models/Types";
import { option } from "fp-ts";
import React, {
  useContext,
  useReducer,
  Reducer,
  Dispatch,
  useCallback
} from "react";

interface IReducerAction {
  type: "set";
  user: option.Option<IUser>;
}

interface IState {
  user: option.Option<IUser>;
}

export const UserReducer = (
  oldUser: IState,
  action: IReducerAction
): IState => {
  if (action.type === "set") {
    if (option.isSome(action.user)) {
      sessionStorage.setItem("user", JSON.stringify(action.user.value));
    } else {
      sessionStorage.removeItem("user");
    }
    return { user: action.user };
  }
  return oldUser;
};

const UserContext = React.createContext(
  {} as [IState, Dispatch<IReducerAction>]
);

type ContextType = {
  user: option.Option<IUser>;
  setUser: (userOp: option.Option<IUser>) => void;
};

interface IProps {
  reducer: Reducer<any, any>;
  initialState: option.Option<IUser>;
}

export const UserProvider: React.FC<IProps> = props => {
  return (
    <UserContext.Provider
      value={useReducer(props.reducer, { user: props.initialState } as IState)}
    >
      {props.children}
    </UserContext.Provider>
  );
};

export const useUser = (): ContextType => {
  const [user, dispatch] = useContext<[IState, Dispatch<IReducerAction>]>(
    UserContext
  );
  const setUser = useCallback(
    (user: option.Option<IUser>) => {
      dispatch({ type: "set", user: user });
    },
    [dispatch]
  );

  return { user: user.user, setUser: setUser };
};
