import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Theme,
  Typography
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { createStyles, makeStyles } from "@material-ui/styles";
import React from "react";
import MyTable, { ITableDefinition } from "../Components/MyTable";
import { useRessource } from "../Context/RessourceContext";
import { IDossier } from "../models/Types";
import { useExport } from "../Service/FileDownload";
import { formatString } from "../Service/Helper";
import { PositionData } from "../Service/Metadata";
import { ILeistungsposition } from "./../models/Types";

interface IProps {
  dossier: IDossier;
  onClose: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cont: { display: "flex" },
    title: {
      display: "inline-block",
      marginTop: "auto",
      marginBottom: "auto",
    },
  })
);

const definition: ITableDefinition<ILeistungsposition> = {
  entityname: "ExtranetPosition",
  columns: [
    {
      key: "Leistungsbezeichnung",
      label: "ExtranetPosition.RechnungsNr", //Workaround weil Daten 'falsch' Gebunden sind
    },
    {
      key: "LeistungspositionsNr",
    },
    {
      key: "Leistungserbringer",
      label: "ExtranetPosition.Leistungsbezeichnung",
    }, //Workaround weil Daten 'falsch' Gebunden sind
    { key: "Behandlungvon" },
    { key: "Behandlungbis" },
    { key: "Forderung" },
    { key: "nichtversichert", label: "ExtranetPosition.Nichtversichert" },
    { key: "Selbstbehalt" },
    { key: "VVG" },
    { key: "KVG" },
    { key: "Nettoleistung" },
  ],
};
const PositionDialog = (props: IProps) => {
  const classes = useStyles({});
  const [getLabel] = useRessource();

  const { exportCSV } = useExport();
  const onExport = (data: ILeistungsposition[]) => {
    exportCSV(data, definition, `position-${props.dossier.DossierNr}.csv`);
  };

  return (
    <Dialog open={true} onClose={props.onClose} fullScreen>
      <DialogTitle disableTypography>
        <div className={classes.cont}>
          <IconButton onClick={props.onClose}>
            <ArrowBackIcon />
          </IconButton>
          <Typography className={classes.title} variant="h6">
            {getLabel("ExtranetPosition.Leistungen")}
          </Typography>
        </div>
      </DialogTitle>
      <DialogContent>
        <MyTable<ILeistungsposition>
          data={PositionData}
          defaultSort="AnfrageNr"
          definition={definition}
          items={props.dossier.Leistungspositionen}
          actions={{}}
          onExport={onExport}
          filterTitle={formatString(getLabel("ExtranetPosition.Leistungen"))(
            getLabel("ExtranetTable.FilterTitle")
          )}
        />
      </DialogContent>
    </Dialog>
  );
};

export default PositionDialog;
