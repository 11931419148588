import { Button, CircularProgress, Theme, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/styles";
import { option } from "fp-ts/lib";
import { isLeft } from "fp-ts/lib/Either";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Input from "../Components/Input";
import { useRessource } from "../Context/RessourceContext";
import { useUser } from "../Context/UserContext";
import useErrorToast from "../Hooks/useErrorToast";
import useFetch from "../Hooks/useFetch";
import { ILoginDto, ITokenDto, ITokenResponseDto } from "../models/DTOs";

interface IProps {
  loginData: ILoginDto;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textField: {
      margin: theme.spacing(2.5),
    },
    button: {
      float: "right",
    },
    title: {
      marginLeft: 20,
      marginTop: 10,
    },
  })
);

const TokenValidationView = ({ loginData }: IProps) => {
  const classes = useStyles({});
  const [getLabel] = useRessource();
  const [token, setToken] = useState("");
  const { showError } = useErrorToast();
  const { setUser } = useUser();
  const history = useHistory();

  const { fetchFromApi, isLoading } = useFetch<ITokenDto, ITokenResponseDto>({
    method: "Post",
    endpoint: "api/token",
  });

  const validateToken = async () => {
    const dto: ITokenDto = { ...loginData, ZugriffsToken: token };
    const res = await fetchFromApi(dto);
    if (isLeft(res)) {
      if (res.left.Code === 401) {
        showError(getLabel("ExtranetMessage.InvalidToken"));
        return;
      }
      showError(res.left.Message);
      return;
    }
    setUser(
      option.some({
        Email: loginData.UserEmail,
        Token: res.right.Zugriffstoken,
      })
    );
    history.push("/home");
  };

  return (
    <>
      <Typography variant="h6" className={classes.title}>
        {`${getLabel("ExtranetLogin.TokenValidation")}`}
      </Typography>
      <div className={classes.textField}>
        <Input
          label={getLabel("ExtranetLogin.EmailToken")}
          value={token}
          onChange={setToken}
          onEnter={() => !isLoading && validateToken()}
        />
      </div>
      <div className={classes.textField}>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={() => !isLoading && validateToken()}
        >
          {isLoading ? (
            <CircularProgress color="inherit" size={25} />
          ) : (
            getLabel("ExtranetLogin.LoginBtn")
          )}
        </Button>
      </div>
    </>
  );
};

export default TokenValidationView;
