import { TextField, OutlinedTextFieldProps } from "@material-ui/core";
import React from "react";

interface IProps {
  label: string;
  value: string;
  onChange: (value: string) => void;
  className?: string;
  type?: string;
  onEnter?: () => void;
  textfieldProps?: Partial<OutlinedTextFieldProps>;
  error?: string;
}

const Input = (props: IProps) => {
  const keyPress = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      props.onEnter && props.onEnter();
    }
  };

  const label = props.label ? { label: props.label } : null;
  return (
    <TextField
      className={props.className}
      value={props.value}
      onChange={ev => props.onChange(ev.target.value)}
      variant="outlined"
      size="small"
      fullWidth
      type={props.type}
      onKeyDown={keyPress}
      margin="none"
      error={!!props.error}
      helperText={props.error}
      {...label}
      {...props.textfieldProps}
    />
  );
};

export default Input;
