import fileDownload from "js-file-download";
import { isArray } from "util";
import { useRessource } from "../Context/RessourceContext";
import { ITableDefinition } from "../Components/DossierTable";

export const exportDataToFile = <T>(data: T, filename: string) => {
  const replacer = (key, value) => (value === null ? "" : value); // specify how you want to handle null values here
  const header = Object.keys(data[0]);
  if (isArray(data)) {
    let csv = data.map((row) =>
      header
        .map((fieldName) => JSON.stringify(row[fieldName], replacer))
        .join(";")
    );
    csv.unshift(header.join(";"));
    const exportCsv = csv.join("\r\n");
    //const dataStr = JSON.stringify(data);
    fileDownload(exportCsv, filename, "text/csv", "\uFEFF");
  }
};
interface IExportFunctions {
  exportCSV: <T>(
    data: T[],
    definition: ITableDefinition<T>,
    fileName: string,
    excludedColumns?: Array<keyof T>
  ) => void;
}
export const useExport = (): IExportFunctions => {
  const [getLabel] = useRessource();

  const exportCSV = <T>(
    data: T[],
    definition: ITableDefinition<T>,
    fileName: string,
    excludedColumns?: Array<keyof T>
  ) => {
    const replacer = (_, value) => (value === null ? "" : value); // specify how you want to handle null values here
    const columns = definition.columns.filter(
      (col) => !excludedColumns?.includes(col.key) ?? true
    );
    if (isArray(data)) {
      const csv = data.map((row) =>
        columns.map(({ key }) => JSON.stringify(row[key], replacer)).join(";")
      );
      csv.unshift(
        columns
          .map(({ label, key }) =>
            getLabel(label ?? `${definition.entityname}.${key}`)
          )
          .join(";")
      );
      const exportCsv = csv.join("\r\n");
      fileDownload(exportCsv, fileName, "text/csv", "\uFEFF");
    }
  };
  return { exportCSV };
};
