import { either } from "fp-ts";
import { Either } from "fp-ts/lib/Either";
import { useCallback, useState } from "react";
import { IFetchError } from "../models/Types";
interface IProps {
  method: "Get" | "Post";
  endpoint: string;
  abort?: AbortSignal;
}

export interface IResponse<T, TR> {
  fetchFromApi: (
    body?: T,
    signal?: AbortSignal
  ) => Promise<Either<IFetchError, TR>>;
  isLoading: boolean;
}

const useFetch = <T, TR = never>(props: IProps): IResponse<T, TR> => {
  const [isLoading, setIsLoading] = useState(false);

  const fetchFromApi = useCallback(
    async <T, TR>(
      body?: T,
      signal?: AbortSignal
    ): Promise<Either<IFetchError, TR>> => {
      return new Promise(async (resolve) => {
        try {
          setIsLoading(true);
          const response = await fetch(props.endpoint, {
            method: props.method,
            headers: {
              "Content-Type": "application/json",
            },
            body: body && JSON.stringify(body),
            signal: signal,
          });
          if (response.status >= 200 && response.status < 300) {
            const data = await response.text();
            if (data) {
              return resolve(either.right(JSON.parse(data) as TR));
            }
            return resolve(either.right(undefined as never));
          }
          return resolve(
            either.left({ Code: response.status, Message: response.statusText })
          );
        } catch (ex) {
          if (ex.code === DOMException.ABORT_ERR) {
            return resolve(either.left({ Code: 508, Message: ex.message }));
          }
          return resolve(either.left({ Code: 0, Message: ex.message }));
        } finally {
          setIsLoading(false);
        }
      });
    },
    [props.endpoint, props.method]
  );
  return { fetchFromApi, isLoading };
};
export default useFetch;
