import { option } from "fp-ts";
import { isSome, Option } from "fp-ts/lib/Option";
import React, { useState } from "react";
import { useRessource } from "../Context/RessourceContext";
import PositionDialog from "../Dialogs/PositionDialog";
import { useExport } from "../Service/FileDownload";
import { Dossierdata } from "../Service/Metadata";
import { IDossier } from "./../models/Types";
import { formatString } from "./../Service/Helper";
import MyTable from "./MyTable";

interface IProps {
  dossiers: IDossier[];
  definition: ITableDefinition<IDossier>;
}

export interface ITableDefinition<T> {
  entityname: string;
  columns: ITableDefinitionColumn<T>[];
}
export interface ITableDefinitionColumn<T> {
  label?: string;
  key: keyof T;
}

const DossierTable = (props: IProps) => {
  const [selectedDossier, setSelectedDossier] = useState<Option<IDossier>>(
    option.none
  );
  const [getLabel] = useRessource();
  const { exportCSV } = useExport();
  const onOpenDetails = (dos: IDossier) => () => {
    setSelectedDossier(option.some(dos));
  };

  const exportDossiers = (dossiers: IDossier[]) => {
    exportCSV(dossiers, props.definition, "dossiers.csv", [
      "Leistungspositionen",
    ]);
  };

  return (
    <>
      <MyTable<IDossier>
        data={Dossierdata}
        defaultSort="DossierNr"
        definition={props.definition}
        items={props.dossiers}
        actions={{ Leistungspositionen: onOpenDetails }}
        onExport={exportDossiers}
        filterTitle={formatString(getLabel("ExtranetEntity.Dossier"))(
          getLabel("ExtranetTable.FilterTitle")
        )}
      />
      {isSome(selectedDossier) && (
        <PositionDialog
          dossier={selectedDossier.value}
          onClose={() => setSelectedDossier(option.none)}
        />
      )}
    </>
  );
};

export default DossierTable;
