import { Button, Card, Theme } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/styles";
import { either } from "fp-ts";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import useErrorToast from "../Hooks/useErrorToast";
import { useFetchMachine } from "../Hooks/useFetchMachine";
import { IDossier, Language } from "../models/Types";
import { IDossierDto } from "../models/DTOs";
import DossierTable, { ITableDefinition } from "./../Components/DossierTable";
import LoadingDialog from "./../Dialogs/LoadingDialog";
import { mapDossierDto } from "./../Service/Mapper";
import { useUser } from "../Context/UserContext";
import { option } from "fp-ts";
import { useRessource, useRessourceReducer } from "../Context/RessourceContext";
import { useSnackbar } from "notistack";
import useFetch from "../Hooks/useFetch";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: "calc(100% - 40px)",
      display: "flex",
      padding: 20,
    },
    retryButton: {
      zIndex: 1100,
      position: "absolute",
      left: "50%",
      top: "50%",
    },
  })
);

const createDefinition = (lang: Language): ITableDefinition<IDossier> => {
  return {
    entityname: "ExtranetDossier",
    columns: [
      { key: "Versicherter" },
      {
        key: "VersicherterNr",
      },
      { key: "Agentur" },
      {
        key: "Ereignisdatum",
      },
      { key: "Haftungsgrund" },
      { key: "DossierNr" },
      { key: "Dossierbearbeiter" },
      { key: "Leistungspositionen", label: "ExtranetDossier.Details" },
      { key: "Erledigt" },
      {
        key: `DossierErledigungsgrund${lang.toString()}` as any,
        label: "ExtranetDossier.DossierErledigungsgrund",
      },
      { key: "Fakturiert" },
      { key: "LetzteRechnungstellung" },
      { key: "Zahlungseingang" },
      {
        key: "LetzterZahlungsEingang",
      },
    ],
  };
};

const DossierOverview: React.FC = (props) => {
  const classes = useStyles({});
  const [getLabel] = useRessource();
  const [dossierList, setDossierList] = useState<IDossier[]>([]);
  const { showError } = useErrorToast();
  const [fetchState, sendEvent] = useFetchMachine();
  const [controller, setController] = useState(new AbortController());
  const history = useHistory();
  const [state] = useRessourceReducer();
  const { setUser } = useUser();
  const { enqueueSnackbar } = useSnackbar();
  const [definition, setDefinition] = useState(
    createDefinition(state.language)
  );
  const { fetchFromApi } = useFetch<never, IDossierDto[]>({
    endpoint: "api/Dossier",
    method: "Get",
  });

  const loadDossier = useCallback(
    async (controller: AbortController) => {
      sendEvent("FETCH");
      const result = await fetchFromApi(undefined, controller.signal);
      if (either.isRight(result)) {
        setDossierList(result.right.map((dto) => mapDossierDto(dto)));
        sendEvent("RESOLVE");
      } else {
        if (result.left.Code === 508) {
          enqueueSnackbar(getLabel("ExtranetMessage.RequestAborted"), {
            variant: "warning",
          });
          return;
        }
        sendEvent("REJECT");
        if (result.left.Code === 401) {
          showError(getLabel("ExtranetMessage.SessionEnded"));
          setUser(option.none);
          history.push("/login");
          return;
        }
        showError(result.left);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    loadDossier(controller);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadDossier]);

  useEffect(() => {
    setDefinition(createDefinition(state.language));
  }, [state.language]);

  const onAbort = () => {
    sendEvent("ABORT");
    controller.abort();
    setController(new AbortController());
  };

  return (
    <div className={classes.root}>
      <Card elevation={5}>
        <DossierTable dossiers={dossierList} definition={definition} />
        {fetchState.value === "loading" && (
          <LoadingDialog
            title={getLabel("ExtranetMessage.LoadingDossier")}
            onAbort={onAbort}
          />
        )}
        {fetchState.matches("aborted") && (
          <Button
            className={classes.retryButton}
            onClick={() => loadDossier(controller)}
            variant="contained"
            color="primary"
          >
            {getLabel("ExtranetTable.Reload")}
          </Button>
        )}
      </Card>
    </div>
  );
};

export default DossierOverview;
