import { option } from "fp-ts";
import { Option } from "fp-ts/lib/Option";
import { pipe } from "fp-ts/lib/pipeable";
import React, { Dispatch, useContext, useReducer } from "react";
import { IRessource, Language } from "./../models/Types";

interface IReducerAction {
  type: "set" | "setLanguage";
  ressourceList?: Option<IRessource[]>;
  language?: Language;
}

interface IState {
  ressourceList: Option<IRessource[]>;
  language: Language;
}

export const RessourceReducer = (
  oldState: IState,
  action: IReducerAction
): IState => {
  if (action.type === "set" && action.ressourceList) {
    return { ...oldState, ressourceList: action.ressourceList };
  } else if (action.type === "setLanguage" && action.language) {
    localStorage.setItem("lang", action.language.toString());
    return { ...oldState, language: action.language };
  }
  return oldState;
};

const RessourceContext = React.createContext(
  {} as [IState, Dispatch<IReducerAction>]
);

const getLanguageFromLocalstorage = (): Language => {
  const langStr = localStorage?.getItem("lang");
  switch (langStr?.toUpperCase()) {
    case "F":
      return Language.F;
    case "I":
      return Language.I;
    default:
      return Language.D;
  }
};

const initialState: IState = {
  ressourceList: option.none,
  language: getLanguageFromLocalstorage(),
};

export const RessourceProvider: React.FC = (props) => {
  return (
    <RessourceContext.Provider
      value={useReducer(RessourceReducer, initialState)}
    >
      {props.children}
    </RessourceContext.Provider>
  );
};

export const useRessourceReducer = (): [IState, Dispatch<IReducerAction>] => {
  return useContext(RessourceContext);
};

const getRessourceTextForLang = (res: IRessource) => (lang: Language) => {
  switch (lang) {
    case Language.D:
      return res.TextD || ressourceForLangNotFound(res.RessourcenName)(lang);
    case Language.F:
      return res.TextF || ressourceForLangNotFound(res.RessourcenName)(lang);
    case Language.I:
      return res.TextI || ressourceForLangNotFound(res.RessourcenName)(lang);
  }
};

const ressourceNotFound = (name: string) => {
  return `Ressource "${name}" not found`;
};

const ressourceForLangNotFound = (name: string) => (lang: Language) => {
  return `Ressource "${name}" for language "${lang}" not found `;
};

const getValue = (detail: string) => (name: string) => (lang: Language) => (
  resList: IRessource[]
) => {
  const res = resList.find(
    (res) => res.RessourcenDetail === detail && res.RessourcenName === name
  );
  if (!res) {
    return ressourceNotFound(name);
  }
  return getRessourceTextForLang(res)(lang);
};

const getLabel = (state: IState) => (name: string, language?: Language) => {
  return pipe(
    state.ressourceList,
    option.map(getValue("label")(name)(language || state.language)),
    option.getOrElse(() => ressourceNotFound(name))
  );
};

const getDesc = (state: IState) => (name: string, language?: Language) => {
  return pipe(
    state.ressourceList,
    option.map(getValue("description")(name)(language || state.language)),
    option.getOrElse(() => ressourceNotFound(name))
  );
};

const isInDictionary = (state: IState) => (name: string) => {
  if (option.isSome(state.ressourceList)) {
    return !!state.ressourceList.value.find(
      (res) => res.RessourcenName === name
    );
  }
  return false;
};
export const useRessource = (): [
  (name: string, language?: Language) => string,
  (name: string, language?: Language) => string,
  (name: string) => boolean
] => {
  const [state] = useContext<[IState, Dispatch<IReducerAction>]>(
    RessourceContext
  );
  return [getLabel(state), getDesc(state), isInDictionary(state)];
};
