import { useMachine } from "@xstate/react";
import { Machine, State } from "xstate";

interface IFetchState {
  states: {
    initial: {};
    loading: {};
    resolved: {};
    rejected: {};
    aborted: {};
  };
}
enum EventTypes {
  FETCH = "FETCH",
  RESOLVE = "RESOLVE",
  REJECT = "REJECT",
  ABORT = "ABORT"
}
type FetchEvent = { type: EventTypes };

const fetchmachine = Machine<IFetchState, FetchEvent>({
  id: "fetch",
  initial: "initial",
  states: {
    initial: { on: { FETCH: "loading" } },
    loading: {
      on: { RESOLVE: "resolved", REJECT: "initial", ABORT: "aborted" }
    },
    resolved: { on: {} },
    aborted: { on: { FETCH: "loading" } }
  }
});

export const useFetchMachine = (): [
  State<IFetchState, FetchEvent, any, any>,
  (
    event: keyof typeof EventTypes | { Type: keyof typeof EventTypes }
  ) => State<any>
] => {
  const [state, send] = useMachine(fetchmachine);
  return [state, send as any];
};
