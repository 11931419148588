import { Button, CircularProgress, Theme, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/styles";
import { either } from "fp-ts";
import { useSnackbar } from "notistack";
import React from "react";
import { useRessource } from "../Context/RessourceContext";
import useFetch from "../Hooks/useFetch";
import useFormState from "../Hooks/useFormState";
import { ILoginDto } from "../models/DTOs";
import Input from "./../Components/Input";
import useErrorToast from "./../Hooks/useErrorToast";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gutter: {
      "& > *": {
        margin: theme.spacing(2.5),
      },
    },
    button: {
      float: "right",
    },
    title: {
      marginLeft: 20,
      marginTop: 10,
    },
  })
);

interface IProps {
  stepForward: (data: ILoginDto) => void;
}

const LoginView = ({ stepForward }: IProps) => {
  const classes = useStyles({});
  const [getLabel] = useRessource();
  const { showError } = useErrorToast();
  const { closeSnackbar } = useSnackbar();

  const { fetchFromApi, isLoading } = useFetch<ILoginDto>({
    endpoint: "api/login",
    method: "Post",
  });

  const [loginData, , handeChange] = useFormState<ILoginDto>({
    UserEmail: "",
    UserPassword: "",
  });

  const onSuccess = () => {
    stepForward(loginData);
  };
  const login = async () => {
    const result = await fetchFromApi(loginData);
    if (either.isRight(result)) {
      onSuccess();
      closeSnackbar();
    } else {
      if (result.left.Code === 401) {
        showError(getLabel("ExtranetMessage.InvalidLoginData"));
      } else {
        showError(result.left.Message);
      }
    }
  };

  return (
    <>
      <Typography variant="h6" className={classes.title}>
        {`${getLabel("ExtranetLogin.LoginTitle")}`}
      </Typography>
      <div className={classes.gutter}>
        <div>
          <Input
            label={getLabel("ExtranetBenutzer.Email")}
            value={loginData.UserEmail}
            onChange={handeChange("UserEmail")}
          />
        </div>
        <div>
          <Input
            label={getLabel("ExtranetBenutzer.Passwort")}
            value={loginData.UserPassword}
            onChange={handeChange("UserPassword")}
            type="password"
            onEnter={() => !isLoading && login()}
          />
        </div>
        <div>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={() => !isLoading && login()}
          >
            {isLoading ? (
              <CircularProgress color="inherit" size={25} />
            ) : (
              getLabel("ExtranetLogin.LoginBtn")
            )}
          </Button>
        </div>
      </div>
    </>
  );
};

export default LoginView;
