import React from "react";
import { Switch, Route, Redirect, useHistory } from "react-router-dom";
import { useUser } from "./Context/UserContext";
import { option } from "fp-ts";
import DossierOverview from "./Views/DossierOverview";
import LoginForm from "./Views/LoginForm";
import { styled } from "@material-ui/core";
import { APPBAR_HEIGHT } from "./Components/Appbar";

interface PrivateRoute {
  path: string | string[];
  exact?: boolean;
}

const PrivateRoute: React.FC<PrivateRoute> = (props) => {
  const { user } = useUser();
  if (option.isSome(user)) {
    return (
      <Route path={props.path} exact={props.exact}>
        {props.children}
      </Route>
    );
  }
  return <Redirect to="/login" />;
};

const Content = styled("div")({
  height: `calc(100% - ${APPBAR_HEIGHT}px)`,
});

const Router = () => {
  const history = useHistory();
  return (
    <Content>
      <Switch>
        <Route exact path="/">
          <Redirect to={{ ...history.location, pathname: "/home" }} />
        </Route>
        <Route exact path="/login">
          <LoginForm />
        </Route>
        <PrivateRoute path={"/home"}>
          <DossierOverview />
        </PrivateRoute>
      </Switch>
    </Content>
  );
};

export default Router;
