import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { either, option } from "fp-ts";
import { Location } from "history";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import Appbar from "./Components/Appbar";
import { useRessourceReducer } from "./Context/RessourceContext";
import { UserProvider, UserReducer } from "./Context/UserContext";
import useErrorToast from "./Hooks/useErrorToast";
import useFetch from "./Hooks/useFetch";
import { IRessource, IUser, Language } from "./models/Types";
import Router from "./Router";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: "100vh",
      width: "100vw",
      overflowX: "hidden",
    },
  })
);

const useComponentDidMount = (func: React.EffectCallback) =>
  useEffect(func, []);

function App() {
  const classes = useStyles();

  const [ressourceState, dispatch] = useRessourceReducer();
  const { showError } = useErrorToast();
  const history = useHistory();

  const { fetchFromApi } = useFetch<never, IRessource[]>({
    endpoint: "api/ressource",
    method: "Get",
  });

  const sessionUser = (() => {
    const userInStore = sessionStorage.getItem("user");
    if (!userInStore) {
      return option.none;
    }
    try {
      const userObj: IUser = JSON.parse(userInStore);
      return option.some(userObj);
    } catch {
      return option.none;
    }
  })();

  const onSuccess = (resList: IRessource[]) => {
    dispatch({ type: "set", ressourceList: option.some(resList) });
    return resList;
  };

  useEffect(() => {
    const getLanguageFromSearch = (search: string) => {
      const params = new URLSearchParams(search);
      const lang = params.get("lang")?.toUpperCase();
      if (lang?.startsWith("F")) {
        return Language.F;
      }
      if (lang?.startsWith("D")) {
        return Language.D;
      }
      if (lang?.startsWith("I")) {
        return Language.I;
      }
      return ressourceState.language;
    };

    const setLanguage = (location: Location) => {
      const language = getLanguageFromSearch(location.search);
      if (language !== ressourceState.language) {
        dispatch({ type: "setLanguage", language });
      }
    };

    const unlisten = history.listen(setLanguage);

    setLanguage(history.location);

    return () => unlisten && unlisten();
  }, [dispatch, history, ressourceState.language]);

  useComponentDidMount(() => {
    const loadRessources = async () => {
      const result = await fetchFromApi();
      if (either.isRight(result)) {
        onSuccess(result.right);
      } else {
        showError("Error while loading the Ressources");
      }
    };
    loadRessources();
  });

  return (
    <UserProvider reducer={UserReducer} initialState={sessionUser}>
      <div className={classes.root}>
        <Appbar />
        <Router />
      </div>
    </UserProvider>
  );
}

export default App;
