export enum Language {
  D = "D",
  F = "F",
  I = "I",
}

export interface IUser {
  Email: string;
  Token: string;
}

export interface IRessource {
  RessourcenDetail: string;
  RessourcenName: string;
  TextD: string;
  TextF: string;
  TextI: string;
}

export interface IFetchError {
  Code: number;
  Message: string;
}

export interface IDossier {
  DossierNr: string;
  KundenNr: string;
  HauptKundenNr: string;
  Ereignisdatum: Date;
  Versicherter: string;
  VersicherterNr: string;
  Haftungsgrund: string;
  AgenturNr: string;
  Agentur: string;
  DossierbearbeiterMaNr: string;
  Dossierbearbeiter: string;
  Erledigt: boolean;
  DossierErledigungsgrund: string;
  DossierErledigungsgrundD: string;
  DossierErledigungsgrundF: string;
  DossierErledigungsgrundI: string;
  Fakturiert: number;
  Zahlungseingang: string;
  LetzteRechnungstellung: Date;
  LetzterZahlungsEingang: Date;
  Abschlussdatum: Date;
  LeistungspositionenXml: string;
  Leistungspositionen: ILeistungsposition[];
}

export interface ILeistungsposition {
  LeistungspositionsNr: string;
  Leistungspositionstyp: string;
  RegressNr: string;
  Leistungsbezeichnung: string;
  Leistungsdetail: string;
  Rechnungsdatum: string;
  Behandlungsart: string;
  BehandlungsartManuellGesetzt: string;
  RechnungsNr: string;
  Behandlungvon: Date;
  Behandlungbis: Date;
  SpitalNr: string;
  Leistungserbringer: string;
  Rechnungsbetrag: string;
  Selbstbehalt: string;
  nichtversichert: string;
  Ausgleich: string;
  Uebertrag: string;
  VVG: string;
  KVG: string;
  Nettoleistung: string;
  AnteilSSS: string;
  AnteilKunde: string;
  Quote: string;
  Forderung: string;
  DokumentNr: string;
  Bemerkung: string;
  KantonstriageNr: string;
  AnfrageNr: string;
  ImportNr: string;
}
