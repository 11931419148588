import {
  Checkbox,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import { isAfter, isBefore } from "date-fns";
import { isSameDay } from "date-fns/esm";
import { Either, isRight, left, right } from "fp-ts/lib/Either";
import React, { useEffect, useState, useCallback } from "react";
import { IMetadata, IMetadataItem } from "../Service/Metadata";
import DatePicker from "./DatePicker";
import Input from "./Input";
import Select, { IKeyValue } from "./Select";
import { useRessource } from "../Context/RessourceContext";

export type FilterFunction<T> = (dos: T) => boolean;

export enum FilterMethod {
  equals = 0,
  notEquals = 1,
  contains = 2,
  startsWith = 3,
  endsWith = 4,
  greaterThan = 5,
  lessThan = 6,
}

const compareDate = (dateLeft: Date, dateRight: Date) => (
  meth: FilterMethod
) => {
  switch (meth) {
    case FilterMethod.equals:
      return isSameDay(dateLeft, dateRight);
    case FilterMethod.notEquals:
      return !isSameDay(dateLeft, dateRight);
    case FilterMethod.lessThan:
      return isBefore(dateLeft, dateRight);
    case FilterMethod.greaterThan:
      return isAfter(dateLeft, dateRight);
    default:
      return false;
  }
};

const compare = (left: string | number, right: string | number) => (
  meth: FilterMethod
) => {
  switch (meth) {
    case FilterMethod.equals:
      if (typeof left === "string" && typeof right === "string") {
        return left.toLowerCase() === right.toLowerCase();
      }
      return left === right;
    case FilterMethod.notEquals:
      if (typeof left === "string" && typeof right === "string") {
        return left.toLowerCase() !== right.toLowerCase();
      }
      return left !== right;
    case FilterMethod.contains:
      if (typeof left === "number" || typeof right === "number") {
        return false;
      }
      return left.toLowerCase().includes(right.toLowerCase());
    case FilterMethod.startsWith:
      if (typeof left === "number" || typeof right === "number") {
        return false;
      }
      return left.toLowerCase().startsWith(right.toLowerCase());
    case FilterMethod.endsWith:
      if (typeof left === "number" || typeof right === "number") {
        return false;
      }
      return left.toLowerCase().endsWith(right.toLowerCase());
    case FilterMethod.greaterThan:
      return left > right;
    case FilterMethod.lessThan:
      return left < right;
    default:
      return false;
  }
};

type GetLabelType = (key: string) => string;

interface IItems<T> {
  left: T;
  right: T;
}

const parseItems = (leftItem: any, rightItem: any) => (
  metadata: IMetadataItem
): Either<IItems<string | number>, IItems<Date>> => {
  if (metadata.type === "number") {
    if (!isNaN(Number(leftItem)) && !isNaN(Number(leftItem))) {
      return left({
        left: Number(leftItem),
        right: Number(rightItem),
      } as IItems<number>);
    }
  }
  if (metadata.type === "Date") {
    leftItem = leftItem || new Date(1900, 0, 1);
    rightItem = rightItem || new Date(1900, 0, 1);
    if (leftItem instanceof Date && rightItem instanceof Date) {
      return right({ left: leftItem, right: rightItem });
    }
  }
  return left({ left: leftItem, right: rightItem } as IItems<string>);
};

const getFilterMethod = (meth: FilterMethod) => <T extends unknown>(
  metadata: IMetadata<T>
) => (key: keyof T) => (param: string | number | Date | boolean) => {
  return (item: T) => {
    const items = parseItems(item[key] as any, param as any)(metadata[key]);
    if (isRight(items)) {
      return compareDate(items.right.left, items.right.right)(meth);
    }
    return compare(items.left.left, items.left.right)(meth);
  };
};

const stringMethods = (getLabel: GetLabelType): IKeyValue[] => [
  { key: FilterMethod.equals.toString(), value: getLabel("ExtranetTable.FilterEquals") },
  { key: FilterMethod.notEquals.toString(), value: getLabel("ExtranetTable.FilterNotEquals") },
  { key: FilterMethod.contains.toString(), value: getLabel("ExtranetTable.FilterContains")},
  { key: FilterMethod.startsWith.toString(), value: getLabel("ExtranetTable.FilterBegins") },
  { key: FilterMethod.endsWith.toString(), value: getLabel("ExtranetTable.FilterEnds") },
];
const boolMethods = (getLabel: GetLabelType): IKeyValue[] => [
  { key: FilterMethod.equals.toString(), value: getLabel("ExtranetTable.FilterEquals") },
  { key: FilterMethod.notEquals.toString(), value: getLabel("ExtranetTable.FilterNotEquals") },
];
const dateMethods = (getLabel: GetLabelType): IKeyValue[] => [
  { key: FilterMethod.equals.toString(), value: getLabel("ExtranetTable.FilterEquals") },
  { key: FilterMethod.notEquals.toString(), value: getLabel("ExtranetTable.FilterNotEquals") },
  { key: FilterMethod.greaterThan.toString(), value: getLabel("ExtranetTable.FilterAfter") },
  { key: FilterMethod.lessThan.toString(), value: getLabel("ExtranetTable.FilterBefore") },
];
const numberMethods = (getLabel: GetLabelType): IKeyValue[] => [
  { key: FilterMethod.equals.toString(), value: getLabel("ExtranetTable.FilterEquals") },
  { key: FilterMethod.notEquals.toString(), value: getLabel("ExtranetTable.FilterNotEquals") },
  { key: FilterMethod.greaterThan.toString(), value: getLabel("ExtranetTable.FilterGreater") },
  { key: FilterMethod.lessThan.toString(), value: getLabel("ExtranetTable.FilterLess") },
];
export interface IFilterFunction<T> {
  func: FilterFunction<T>;
  col: string;
  param: string | boolean | Date;
  filterMethod: FilterMethod;
}
interface IProps<T> {
  columns: IKeyValue[];
  filter: IFilterFunction<T>[];
  metadata: IMetadata<T>;
  addFilter: (filter: IFilterFunction<T>) => void;
  removeFilter: (index: number) => void;
  updateFilter: (index: number) => (func: IFilterFunction<T>) => void;
  className?: string;
}

const createFilterFunction = <T extends unknown>(meth: FilterMethod) => (
  param: string | boolean | Date
) => (metaData: IMetadata<T>) => (col: keyof T): FilterFunction<T> => {
  return getFilterMethod(meth)<T>(metaData)(col)(param);
};

const getMethodList = <T extends unknown>(column: keyof T) => (
  metadata: IMetadata<T>
) => (getLabel: GetLabelType) =>  {
  if (!column) {
    return [];
  }
  const metadataItem = metadata[column as keyof T];
  if (metadataItem) {
    switch (metadataItem.type) {
      case "Date":
        return dateMethods(getLabel);
      case "number":
        return numberMethods(getLabel);
      case "string":
        return stringMethods(getLabel);
      case "boolean":
        return boolMethods(getLabel);
    }
  }
  return [];
};

const Filtertable = <T extends unknown>(props: IProps<T>) => {
  const [column, setColumn] = useState("");
  const [method, setMethod] = useState("");
  const [param, setParam] = useState<string | boolean | Date>("");
  const [getLabel] = useRessource();

  const [filterCopies, setFilterCopies] = useState(
    [...props.filter].map((f) => {
      return { ...f };
    })
  );

  useEffect(() => {
    setFilterCopies(
      [...props.filter].map((f) => {
        return { ...f };
      })
    );
  }, [props.filter]);

  const createFilter = useCallback(
    (col: keyof T) => (param: string | boolean | Date) => (
      method: string
    ): IFilterFunction<T> => {
      return {
        col: col as string,
        param: param,
        func: createFilterFunction<T>(Number(method))(param)(props.metadata)(
          col
        ),
        filterMethod: Number(method) as FilterMethod,
      };
    },
    [props.metadata]
  );

  const onLostFocus = () => {
    if (column && method) {
      props.addFilter(createFilter(column as keyof T)(param)(method));
      setColumn("");
      setParam("");
      setMethod("");
    }
  };

  useEffect(() => {
    let paramCopy = param;
    if (props.metadata[column]?.type === "Date" && !param) {
      paramCopy = new Date();
    } else if (
      props.metadata[column]?.type === "boolean" &&
      (param === null || param === undefined || param === "")
    ) {
      paramCopy = true;
    }
    if (column && method) {
      props.addFilter(createFilter(column as keyof T)(paramCopy)(method));
      setColumn("");
      setParam("");
      setMethod("");
    }
  }, [column, createFilter, method, param, props]);

  const onFilterChanged = (index: number) => (
    key: keyof IFilterFunction<T>
  ) => (value: string | boolean | Date) => {
    const arrCopy = [...filterCopies];
    const elem = arrCopy.splice(index, 1).pop();
    if (elem) {
      (elem[key] as any) = value;
      arrCopy.splice(index, 0, { ...elem });
      setFilterCopies(arrCopy);
    }
  };

  const onUpdateLostFocus = (index: number) => () => {
    const elemArr = [...filterCopies].splice(index, 1);
    if (elemArr.length > 0) {
      const elem = elemArr[0];
      elem.func = createFilterFunction<T>(Number(elem.filterMethod))(
        elem.param
      )(props.metadata)(elem.col as keyof T);
      props.updateFilter(index)(elem);
    }
  };

  return (
    <Table size="small" className={props.className}>
      <TableHead>
        <TableRow>
          <TableCell>{getLabel("ExtranetTable.Column")}</TableCell>
          <TableCell>{getLabel("ExtranetTable.FilterMethod")}</TableCell>
          <TableCell>{getLabel("ExtranetTable.FilterParam")}</TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {filterCopies.map((filter, index) => {
          return (
            <TableRow key={`row${index}`}>
              <TableCell padding="none">
                <Select
                  textfieldProps={{
                    onBlur: onUpdateLostFocus(index),
                  }}
                  keyProp="key"
                  valueProp="value"
                  items={props.columns}
                  label=""
                  value={filter.col}
                  handleChange={onFilterChanged(index)("col")}
                />
              </TableCell>
              <TableCell padding="checkbox">
                <Select
                  textfieldProps={{
                    onBlur: onUpdateLostFocus(index),
                    disabled: filter.col ? false : true,
                  }}
                  keyProp="key"
                  valueProp="value"
                  items={getMethodList(filter.col as keyof T)(props.metadata)(getLabel)}
                  label=""
                  value={filter.filterMethod.toString()}
                  handleChange={onFilterChanged(index)("filterMethod")}
                />
              </TableCell>
              <ParamForDataType
                metadata={props.metadata[filter.col]}
                disabled={filter.col ? false : true}
                onChange={onFilterChanged(index)("param")}
                value={filter.param}
                onBlur={onUpdateLostFocus(index)}
              />
              <TableCell padding="none">
                <IconButton onClick={() => props.removeFilter(index)}>
                  <ClearIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          );
        })}
        <TableRow>
          <TableCell padding="none">
            <Select
              textfieldProps={{
                onBlur: onLostFocus,
              }}
              keyProp="key"
              valueProp="value"
              items={props.columns}
              label=""
              value={column}
              handleChange={(val) => {
                setColumn(val);
              }}
            />
          </TableCell>
          <TableCell padding="checkbox">
            <Select
              textfieldProps={{
                onBlur: onLostFocus,
                disabled: column ? false : true,
              }}
              keyProp="key"
              valueProp="value"
              items={getMethodList(column as keyof T)(props.metadata)(getLabel)}
              label=""
              value={method.toString()}
              handleChange={(val) => {
                setMethod(val);
              }}
            />
          </TableCell>
          <ParamForDataType
            metadata={props.metadata[column]}
            disabled={column ? false : true}
            onChange={(val) => setParam(val)}
            value={param}
            onBlur={onLostFocus}
          />
          <TableCell padding="none"></TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

interface IParamProps {
  metadata: IMetadataItem;
  value: any;
  onChange: (value: string | boolean | Date) => void;
  onBlur: () => void;
  disabled: boolean;
}
const ParamForDataType = (props: IParamProps) => {
  if (props.metadata && props.metadata.type === "boolean") {
    return (
      <TableCell padding="none">
        <Checkbox
          checked={!!props.value}
          color="primary"
          onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
            props.onChange(ev.target.checked);
            props.onBlur();
          }}
        />
      </TableCell>
    );
  }
  if (props.metadata && props.metadata.type === "Date") {
    return (
      <TableCell padding="none">
        <DatePicker
          value={props.value}
          onChange={(val) => props.onChange(val)}
          inputProps={{ onBlur: props.onBlur }}
          onOk={props.onBlur}
        />
      </TableCell>
    );
  }
  return (
    <TableCell padding="none">
      <Input
        textfieldProps={{
          onBlur: props.onBlur,
          disabled: props.disabled,
        }}
        value={props.value}
        label=""
        onChange={props.onChange}
      />
    </TableCell>
  );
};

export default Filtertable;
