import { MenuItem, OutlinedTextFieldProps, TextField } from "@material-ui/core";
import React from "react";

interface IProps {
  label: string;
  value: string;
  items: any[];
  keyProp: string;
  valueProp: string;
  handleChange: (value: string) => void;
  textfieldProps?: Partial<OutlinedTextFieldProps>;
}

export interface IKeyValue {
  key: string;
  value: string;
}

const Select = (props: IProps) => {
  const handleChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    props.handleChange(ev.target.value);
  };
  const label = props.label ? { label: props.label } : null;
  return (
    <TextField
      select
      size="small"
      variant="outlined"
      value={props.value}
      onChange={handleChange}
      fullWidth
      {...label}
      {...props.textfieldProps}
    >
      {props.items.map(option => {
        return (
          <MenuItem
            key={option[props.keyProp]}
            dense
            value={option[props.keyProp]}
          >
            {option[props.valueProp]}
          </MenuItem>
        );
      })}
    </TextField>
  );
};

export default Select;
