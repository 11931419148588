import {
  Badge,
  Button,
  Checkbox,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Theme,
  LabelDisplayedRowsArgs
} from "@material-ui/core";
import DetailsIcon from "@material-ui/icons/Details";
import { createStyles, makeStyles } from "@material-ui/styles";
import { format, isSameDay } from "date-fns";
import React, { useMemo, useState } from "react";
import { useRessource } from "../Context/RessourceContext";
import { IMetadata } from "../Service/Metadata";
import { IFilterFunction } from "./Filtertable";
import TableFilter from "./TableFilter";
import { pipe } from "fp-ts/lib/pipeable";
import { formatString } from "./../Service/Helper";

type Action<T> = { [key in keyof Partial<T>]: (item: T) => () => void };

interface IProps<T> {
  filterTitle: string;
  items: T[];
  definition: ITableDefinition<T>;
  defaultSort: keyof T;
  data: IMetadata<T>;
  actions: Action<T>;
  onExport?: (items: T[]) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cell: { fontSize: theme.typography.caption.fontSize },
    smallCell: {
      padding: "1px 10px 1px 10px",
      height: 40
    },
    container: {
      maxHeight: "calc(100% - 52px)",
      minHeight: "calc(100% - 52px)",
      height: "calc(100% - 52px)"
    },
    pagination: {
      display: "inline-block",
      float: "right"
    },
    filter: {
      display: "inline-block",
      float: "left"
    },
    exportButtonCont: { float: "right", height: 52, display: "flex" },
    exportButton: {
      margin: "auto 0"
    },
    noEntriesLabel: {
      marginTop: 10,
      marginLeft: 10
    }
  })
);

export interface ITableDefinition<T> {
  entityname: string;
  columns: ITableDefinitionColumn<T>[];
}
export interface ITableDefinitionColumn<T> {
  label?: string;
  key: keyof T;
}

const MyTable = <T extends unknown>(props: IProps<T>) => {
  const [getLabel] = useRessource();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [filterList, setFilterList] = useState<IFilterFunction<T>[]>([]);
  const [orderBy, setOrderBy] = useState<[keyof T, "asc" | "desc"]>([
    props.defaultSort,
    "asc"
  ]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const applyFilter = (func: IFilterFunction<T>[]) => {
    setFilterList([...func]);
    setPage(0);
  };

  const applyAllFilter = (item: T) => (filterList: IFilterFunction<T>[]) => {
    if (filterList.length < 1) {
      return true;
    }
    return filterList.every(filter => filter.func(item));
  };

  const clearFilter = () => {
    setFilterList([]);
  };

  const onSortChanged = (key: keyof T) => () => {
    if (orderBy[0] === key) {
      if (orderBy[1] === "asc") {
        setOrderBy([key, "desc"]);
      } else {
        setOrderBy([key, "asc"]);
      }
    } else {
      setOrderBy([key, "asc"]);
    }
  };

  const sortMethod = useMemo(() => {
    return (a: T, b: T) => {
      if (orderBy[1] === "asc") {
        if (
          typeof a[orderBy[0]] === "string" &&
          typeof b[orderBy[0]] === "string"
        ) {
          return (a[orderBy[0]] as any)
            .toString()
            .localeCompare((b[orderBy[0]] as any).toString());
        }
        if (props.data[orderBy[0]].type === "array") {
          const arr1 = (a[orderBy[0]] as unknown) as Array<any>;
          const arr2 = (b[orderBy[0]] as unknown) as Array<any>;
          return arr1.length - arr2.length;
        }
        return (a[orderBy[0]] as any) - (b[orderBy[0]] as any);
      }
      if (orderBy[1] === "desc") {
        if (
          typeof a[orderBy[0]] === "string" &&
          typeof b[orderBy[0]] === "string"
        ) {
          return (b[orderBy[0]] as any)
            .toString()
            .localeCompare((a[orderBy[0]] as any).toString());
        }
        if (props.data[orderBy[0]].type === "array") {
          const arr1 = (a[orderBy[0]] as unknown) as Array<any>;
          const arr2 = (b[orderBy[0]] as unknown) as Array<any>;
          return arr2.length - arr1.length;
        }
        return (b[orderBy[0]] as any) - (a[orderBy[0]] as any);
      }

      return 0;
    };
  }, [orderBy, props.data]);

  const filteredItems = useMemo(() => {
    return props.items
      .sort(sortMethod)
      .filter(item => applyAllFilter(item)(filterList));
  }, [filterList, props.items, sortMethod]);

  const classes = useStyles();

  const labelDisplayRows = ({
    from,
    to,
    count
  }: LabelDisplayedRowsArgs): React.ReactNode => {
    return pipe(
      getLabel("ExtranetTable.PageOf"),
      formatString(from.toString(), to.toString(), count.toString())
    );
  };

  return (
    <>
      <TablePagination
        rowsPerPageOptions={[50, 100, 500]}
        component="div"
        count={filteredItems.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        size="small"
        className={classes.pagination}
        labelRowsPerPage={getLabel("ExtranetTable.RowsPerPage")}
        labelDisplayedRows={labelDisplayRows}
      />
      {props.onExport && (
        <div className={classes.exportButtonCont}>
          <Button
            color="primary"
            onClick={() => props.onExport!(filteredItems)}
            variant="contained"
            className={classes.exportButton}
          >
            {getLabel("ExtranetTable.Export")}
          </Button>
        </div>
      )}
      <TableFilter
        className={classes.filter}
        table={props.definition}
        filter={filterList}
        applyFilter={applyFilter}
        clearFilter={clearFilter}
        metadata={props.data}
        title={props.filterTitle}
      />
      <TableContainer className={classes.container}>
        <Table size="small" cellPadding={5} stickyHeader>
          <TableHead>
            <TableRow>
              {props.definition.columns.map(col => {
                return (
                  <TableCell
                    key={col.key as string}
                    classes={{ sizeSmall: classes.smallCell }}
                    className={classes.cell}
                  >
                    <TableSortLabel
                      active={orderBy[0] === col.key}
                      direction={orderBy[1]}
                      onClick={onSortChanged(col.key)}
                    >
                      {getLabel(
                        col.label || `${props.definition.entityname}.${col.key}`
                      )}
                    </TableSortLabel>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredItems
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((item, index) => {
                return (
                  <TableRow key={index}>
                    {props.definition.columns.map(col => {
                      return (
                        <MyTableCell<T>
                          key={col.key.toString()}
                          propname={col.key}
                          metadata={props.data}
                          value={item[col.key] ?? ""}
                          action={
                            props.actions[col.key] &&
                            props.actions[col.key](item)
                          }
                        />
                      );
                    })}
                  </TableRow>
                );
              })}
            {filteredItems.length < 1 && (
              <TableRow>
                <TableCell colSpan={props.definition.columns.length}>
                  {getLabel("ExtranetTable.NoEntriesFound")}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

interface ICellProps<T> {
  propname: keyof T;
  metadata: IMetadata<T>;
  value: any;
  action: () => void;
}

const nullDate = new Date(1900, 0, 1); // 01.01.1900

const SmallCell: React.FC = props => {
  const classes = useStyles();
  return (
    <TableCell
      classes={{ sizeSmall: classes.smallCell }}
      className={classes.cell}
    >
      {props.children}
    </TableCell>
  );
};

const MyTableCell = <T extends unknown>(props: ICellProps<T>) => {
  const data = props.metadata[props.propname];
  const { value } = props;
  if (data.type === "string" || value === "") {
    return <SmallCell>{value.toString() ?? ""}</SmallCell>;
  } else if (data.type === "number") {
    return (
      <SmallCell>
        {data.format ? data.format(value) : value.toString()}
      </SmallCell>
    );
  } else if (data.type === "Date") {
    const dateValue = value as Date;
    return (
      <SmallCell>
        {isSameDay(nullDate, dateValue)
          ? ""
          : format(dateValue, "dd.MM.yyyy") ?? ""}
      </SmallCell>
    );
  } else if (data.type === "boolean") {
    const boolvalue = value as boolean;
    return (
      <SmallCell>
        <Checkbox checked={boolvalue} color="primary" />
      </SmallCell>
    );
  } else if (data.type === "array") {
    const arr = value as Array<any>;
    return (
      <SmallCell>
        <IconButton size="small" onClick={props.action}>
          <Badge color="primary" badgeContent={arr.length} max={1000}>
            <DetailsIcon />
          </Badge>
        </IconButton>
      </SmallCell>
    );
  }
  return null;
};

export default MyTable;
