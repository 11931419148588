import { Card, Grid, Step, StepLabel, Stepper, Theme } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/styles";
import { option } from "fp-ts";
import { isSome, Option } from "fp-ts/lib/Option";
import React, { useState } from "react";
import { useRessource } from "./../Context/RessourceContext";
import LoginView from "./LoginView";
import TokenValidationView from "./TokenValidationView";
import { ILoginDto } from "./../models/DTOs";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: "100%",
    },
    card: {
      padding: 10,
    },
    cont: {
      minWidth: "100%",
    },
    textField: {
      margin: "20px 20px",
    },
    login: {
      width: "100%",
    },
    stepper: {
      margin: "auto",
    },
    contentRoot: {
      paddingLeft: 0,
    },
  })
);

enum Steps {
  login = 0,
  tokenValidation = 1,
}

const LoginForm = () => {
  const classes = useStyles({});
  const [activeStep, setActiveStep] = useState(0);
  const [getLabel] = useRessource();

  const [loginData, setLoginData] = useState<Option<ILoginDto>>(option.none);

  const stepForward = (loginData: ILoginDto) => {
    setActiveStep(Steps.tokenValidation);
    setLoginData(option.some(loginData));
  };

  return (
    <Grid
      container
      direction="column"
      className={classes.root}
      justify="center"
    >
      <Grid
        item
        md={6}
        xs={10}
        className={classes.cont}
        container
        justify="center"
        direction="row"
      >
        <Grid item md={5} lg={3} xs={10}>
          <div>
            <Stepper className={classes.stepper} activeStep={activeStep}>
              <Step>
                <StepLabel>{getLabel("ExtranetLogin.LoginTitleStep")}</StepLabel>
              </Step>
              <Step>
                <StepLabel>
                  {getLabel("ExtranetLogin.TokenValidationStep")}
                </StepLabel>
              </Step>
            </Stepper>
            <Card className={classes.card} elevation={5}>
              {activeStep === Steps.login && (
                <LoginView stepForward={stepForward} />
              )}
              {activeStep === Steps.tokenValidation && isSome(loginData) && (
                <TokenValidationView loginData={loginData.value} />
              )}
            </Card>
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default LoginForm;
