import { Button, Theme, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/styles";
import { option } from "fp-ts";
import React from "react";
import { useHistory } from "react-router-dom";
import { useRessource, useRessourceReducer } from "../Context/RessourceContext";
import { useUser } from "../Context/UserContext";
import useFetch from "../Hooks/useFetch";
import { Language } from "../models/Types";
import Select from "./Select";

export const APPBAR_HEIGHT = 72;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: { width: "100vw", height: APPBAR_HEIGHT },
    topBorder: {
      height: 6,
      width: "100%",
      backgroundColor: theme.palette.primary.main,
    },
    appbar: {
      display: "flex",
      justifyContent: "space-between",
    },
    logo: {
      marginTop: 5,
      height: 60,
      marginLeft: 20,
    },
    userData: {
      display: "flex",
      alignItems: "center",
    },
    userText: {
      margin: "auto",
    },
    logoutButton: {
      maxHeight: 40,
      margin: "auto 20px auto 30px",
      textTransform: "none",
      color: theme.palette.primary.main,
    },
    languageContainer: {
      margin: `auto auto auto ${theme.spacing(2)}px`,
      display: "flex",
      width: 150,
    },
  })
);

const Appbar = () => {
  const classes = useStyles({});
  const { user, setUser } = useUser();
  const [getLabel] = useRessource();
  const [state, dispatch] = useRessourceReducer();
  const history = useHistory();

  const { fetchFromApi } = useFetch({
    endpoint: "api/logout",
    method: "Post",
  });

  const logout = async () => {
    setUser(option.none);
    await fetchFromApi();
  };

  return (
    <div className={classes.root}>
      <div className={classes.topBorder}></div>
      <div className={classes.appbar}>
        <img
          src={process.env.PUBLIC_URL + "/images/logo_de.png"}
          alt="Logo"
          className={classes.logo}
        />
        <div className={classes.languageContainer}>
          <Select
            items={[
              { key: Language.D, value: "Deutsch" },
              { key: Language.F, value: "Français" },
              { key: Language.I, value: "Italiano" },
            ]}
            handleChange={(value) => {
              history.push({ ...history.location, search: undefined });
              dispatch({ type: "setLanguage", language: Language[value] });
            }}
            keyProp="key"
            valueProp="value"
            label=""
            value={state.language}
          />
        </div>
        {option.isSome(user) && (
          <div className={classes.userData}>
            <Typography variant="body2" className={classes.userText}>
              {getLabel("ExtranetHeader.LoggedInAs")} <b>{user.value.Email}</b>
            </Typography>
            <Button className={classes.logoutButton} onClick={logout}>
              {getLabel("ExtranetHeader.Logout")}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Appbar;
